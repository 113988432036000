import React from 'react'
import { FaChevronRight, FaFacebook, FaInstagram, FaLinkedin, FaSkype, FaTwitter } from 'react-icons/fa'

const Footer = () => {
  return (
    <div className='bg-[#111111]'>
      <div className='text-[#fff] text-[14px]'>
        <div className='pt-15 pb-7.5 bg-[#1b1b1b] pt-[50px]'>
          <div className='grid grid-cols-3 mx-[15%]'>
            <div className='mb-[30px]'>
              <h3 className='text-[26px] font-[700]'>Wildlife Comforts<span className='text-[#4caf50]'>.</span></h3>
              <p className="text-[14px] mb-0 w-full block">
                Columbia, MD 21045<br />
                United States<br /><br />
              </p>
              <a className='text-gray-400 hover:text-gray-500' href="/login">Login</a>
            </div>

            <div className='mb-[30px]'>
              <h4 className='text-[16px] font-bold pb-[12px]'>Useful Links</h4>
              <ul className='p-0 m-0'>
                <li className='pt-0 py-[10px] flex items-center'><i><FaChevronRight className='pr-[2px] text-white text-[10px]' /></i> <a className='text-[#aaaaaa] transition-all duration-300 inline-block hover:text-[#fff]' href="#hero">Home</a></li>
                <li className='py-[10px] flex items-center'><i><FaChevronRight className='pr-[2px] text-white text-[10px]' /></i> <a className='text-[#aaaaaa] transition-all duration-300 inline-block hover:text-[#fff]' href="#about">About us</a></li>
                <li className='py-[10px] flex items-center'><i><FaChevronRight className='pr-[2px] text-white text-[10px]' /></i> <a className='text-[#aaaaaa] transition-all duration-300 inline-block hover:text-[#fff]' href="#services">Services</a></li>
                <li className='py-[10px] flex items-center'><i><FaChevronRight className='pr-[2px] text-white text-[10px]' /></i> <a className='text-[#aaaaaa] transition-all duration-300 inline-block hover:text-[#fff]' href="#portfolio">Portfolio</a></li>
              </ul>
            </div>

            <div className='mb-[30px]'>
              <h4 className='text-[16px] font-bold pb-[12px]'>Our Services</h4>
              <ul className='p-0 m-0'>
                <li className='pt-0 py-[10px] flex items-center'><i><FaChevronRight className='pr-[2px] text-white text-[10px]' /></i> <a className='text-[#aaaaaa] transition-all duration-300 inline-block hover:text-[#fff]' href="#services">Animal Sitting</a></li>
                <li className='py-[10px] flex items-center'><i><FaChevronRight className='pr-[2px] text-white text-[10px]' /></i> <a className='text-[#aaaaaa] transition-all duration-300 inline-block hover:text-[#fff]' href="#services">Daily Visits</a></li>
                <li className='py-[10px] flex items-center'><i><FaChevronRight className='pr-[2px] text-white text-[10px]' /></i> <a className='text-[#aaaaaa] transition-all duration-300 inline-block hover:text-[#fff]' href="#services">Dog Walking</a></li>
                <li className='py-[10px] flex items-center'><i><FaChevronRight className='pr-[2px] text-white text-[10px]' /></i> <a className='text-[#aaaaaa] transition-all duration-300 inline-block hover:text-[#fff]' href="#services">Pet Taxi</a></li>
                <li className='py-[10px] flex items-center'><i><FaChevronRight className='pr-[2px] text-white text-[10px]' /></i> <a className='text-[#aaaaaa] transition-all duration-300 inline-block hover:text-[#fff]' href="#services">Specialized Care</a>
                </li>
                <li className='py-[10px] flex items-center'><i><FaChevronRight className='pr-[2px] text-white text-[10px]' /></i> <a className='text-[#aaaaaa] transition-all duration-300 inline-block hover:text-[#fff]' href="#services">House Sitting</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className='md:flex py-4'>
          <div className='m-auto text-center md:text-start'>
            <div>
              &copy; Copyright <strong><span>Wildlife Comforts</span></strong>. All Rights
              Reserved
            </div>
          </div>
          <div className='pt-3 md:pt-0 md:text-end'>
            {/* <a className='text-[18px] inline-block bg-[#2b2b2b] text-[#fff] py-[8px] mr-[4px] rounded-md text-center w-[36px] h-[36px] transition-all duration-300 hover:bg-[#4caf50] text-[#fff]' href="#" class="twitter"><i class="bx bxl-twitter"></i></a> */}
            {/* <a className='text-[18px] inline-block bg-[#2b2b2b] text-[#fff] py-[8px] mr-[4px] rounded-md text-center w-[36px] h-[36px] transition-all duration-300 hover:bg-[#4caf50] hover:text-[#fff]' href="#" class="facebook"><i class="bx bxl-facebook"></i></a> */}
            {/* <a className='text-[18px] inline-block bg-[#2b2b2b] text-[#fff] py-[8px] mr-[4px] rounded-md text-center w-[36px] h-[36px] transition-all duration-300 hover:bg-[#4caf50] hover:text-[#fff]' href="#" class="instagram"><i class="bx bxl-instagram"></i></a> */}
            {/* <a className='text-[18px] inline-block bg-[#2b2b2b] text-[#fff] py-[8px] mr-[4px] rounded-md text-center w-[36px] h-[36px] transition-all duration-300 hover:bg-[#4caf50] hover:text-[#fff]' href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a> */}
          </div>
        </div>
      </div>
    </div>

  )
}

export default Footer