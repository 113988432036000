import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { MdOutlineCancel, MdOutlineMessage, MdOutlinePhotoSizeSelectActual } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

// Icons
import { IoHomeSharp } from 'react-icons/io5'
import { CgLogOut } from "react-icons/cg";
import { IoPeopleSharp } from "react-icons/io5";
import { useStateContext } from '../contexts/ContextProvider';
import { signOut } from '../lib/appwrite';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { SiGoogleforms } from 'react-icons/si';
import { TbEyeCog } from 'react-icons/tb';
import { FaRegMessage } from "react-icons/fa6";
import { FaRegCalendarAlt } from "react-icons/fa";


const Sidebar = () => {
  const location = useLocation();
  const { currentColor, activeMenu, setActiveMenu, screenSize, setAccount, setIsLoggedIn, fetchAllowedPages } = useStateContext();
  const [allowedPages, setAllowedPages] = useState([])

  const links = [
    {
      title: 'Home',
      links: [
        {
          name: 'dashboard',
          path: '/',
          icon: <IoHomeSharp />,
        },
        {
          name: 'Access Control',
          path: '/access-control',
          icon: <TbEyeCog />
        },
        {
          name: 'testimonials',
          path: '/admin/testimonials',
          icon: <MdOutlineMessage />
        },
        {
          name: 'photos',
          path: '/admin/photos',
          icon: <MdOutlinePhotoSizeSelectActual />
        },
        {
          name: 'messages',
          path: '/admin/messages',
          icon: <FaRegMessage />
        },
        {
          name: 'customers',
          path: '/admin/customers',
          icon: <IoPeopleSharp />
        },
        {
          name: 'calendar',
          path: '/admin/calendar',
          icon: <FaRegCalendarAlt />
        }
      ],
    },
  ];

  useEffect(() => {
    const updateAllowedPages = async () => {
      const response = await fetchAllowedPages()
      setAllowedPages(response)
    }
    
    updateAllowedPages()
  }, [])

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const handleLogout = () => {
    signOut();
    setAccount(null);
    setIsLoggedIn(false);
  }

  const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2';
  const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link to="/" onClick={handleCloseSideBar} className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
              <img src='' /> <span>Wildlife Comforts</span>
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <div className="mt-10 h-screen">
            {links
              .map((item) => ({
                ...item,
                links: item.links.filter((link) => allowedPages.includes(link.path)),
              }))
              .filter((item) => item.links.length > 0)
              .map((item) => (
                <div key={item.title}>
                  <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                    {item.title}
                  </p>
                  {item.links.map((link) => (
                    <NavLink
                      to={`${link.path}`}
                      key={link.name}
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : '',
                      })}
                      className={({ isActive }) => (isActive ? activeLink : normalLink)}
                    >
                      {link.icon}
                      <span className="capitalize">{link.name}</span>
                    </NavLink>
                  ))}
                </div>
              ))}


            <NavLink
              to='/'
              key='logout'
              onClick={handleLogout}
              className={`${normalLink}`}
            >
              <CgLogOut />
              <span>Logout</span>
            </NavLink>
          </div>

        </>
      )
      }
    </div >
  );
};

export default Sidebar;
