import React, { useEffect, useState, useRef } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { useStateContext } from '../../../../contexts/ContextProvider';
import EditableTable from '../../../../components/EditableTable';
import { Button } from '../../../../components';
import { getPermissionGroups, uploadPermissionRecord, updateDocument, config, getAllDocuments } from '../../../../lib/appwrite';
import DropdownField from '../../../../components/DropdownField';

const EditPageControl = ({ view, setView, selectedRowData, setSelectedRowData, mode, startingPermission }) => {
    const { currentColor } = useStateContext();

    const [selectedPermission, setSelectedPermission] = useState('');
    const [selectedPermissionError, setSelectedPermissionError] = useState(false);
    const [permissionGroups, setPermissionGroups] = useState([]);
    const [defaultPaths, setDefaultPaths] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const initialLoad = useRef(true);
    const pathsModified = useRef(false);

    useEffect(() => {
        const fetchPermissionGroups = async () => {
            const permissions = await getPermissionGroups();
            permissions.push('Custom');
            setPermissionGroups(permissions);

            if (view && selectedRowData) {
                setSelectedPermission(selectedRowData.permissions);
            }
        };

        if (view === true) {
            fetchPermissionGroups();
        }
    }, [view]);

    useEffect(() => {
    }, [selectedPermission])

    useEffect(() => {
        const fetchPermissions = async () => {
            if (!selectedRowData) return;
            if (!selectedPermission) return;

            if (initialLoad.current && selectedPermission.toLowerCase() === 'custom') {
                const permissionsResponse = await getAllDocuments(config.permissionsCollectionId, null, [{permissionName: selectedRowData.accountId}])
                const allowedPages = JSON.parse(permissionsResponse[0].pageAccess)
                setDefaultPaths(allowedPages);
            } else if (selectedPermission.toLocaleLowerCase() !== 'custom') {
                const permissionsResponse = await getAllDocuments(config.permissionsCollectionId, null, [{permissionName: selectedPermission}])
                const allowedPages = JSON.parse(permissionsResponse[0].pageAccess)
                setDefaultPaths(allowedPages);
            }
        };

        if (view === true) {
            fetchPermissions();
        }
    }, [selectedPermission]);

    useEffect(() => {
        const modifyPermission = async () => {
            if (pathsModified.current && !initialLoad.current) {
                setSelectedPermission('Custom');
            } else {
                pathsModified.current = true;
            }
            initialLoad.current = false;
        }
        
        if (view === true) {
            modifyPermission()
        }

    }, [defaultPaths]);

    const handlePathsChange = (newPaths) => {
        pathsModified.current = true;
        setDefaultPaths(newPaths);
    };

    const close_menu = () => {
        setSelectedPermission('');
        setPermissionGroups([]);
        setDefaultPaths([]);
        setSelectedRowData(null);
        setView(false);
        setIsLoading(false);
        initialLoad.current = true;
        pathsModified.current = false;
    };

    const submit = async () => {
        setIsLoading(true);

        try {
            let response;

            if (selectedPermission === '') {
                setSelectedPermissionError(true);
            } else {
                setSelectedPermissionError(false);
            }

            if (selectedPermission === '') {
                setIsLoading(false);
                return;
            }

            if (mode === 'edit') {
                const data = {
                    permissions: selectedPermission.toLowerCase()
                }
                response = await updateDocument(selectedRowData.$id, data, config.userCollectionId)
            }

            const updatedRowData = { ...selectedRowData, permissions: selectedPermission };
            setSelectedRowData(updatedRowData);
            if (updatedRowData.permissions === 'Custom') {
                response = await uploadPermissionRecord(selectedRowData.accountId, true, defaultPaths);
            }

            if (response && response.error) {
                console.error(response.error);
            } else {
                close_menu();
            }
        } catch (error) {
            console.error('An error occurred during submission:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='bg-half-transparent w-screen fixed nav-item top-0 right-0'>
            <div className='float-right h-screen overflow-scroll dark:text-gray-200 bg-white dark:bg-secondary-dark-bg w-[600px]'>
                <div className='flex justify-between items-center p-4 ml-4'>
                    {mode === 'edit' && (
                        <p className='font-semibold text-xl'>Edit Page Access</p>
                    )}
                    {mode === 'create' && (
                        <p className='font-semibold text-xl'>Create Page Access</p>
                    )}
                    {mode === 'view' && (
                        <p className='font-semibold text-xl'>Page Access</p>
                    )}
                    <button
                        type='button'
                        onClick={() => close_menu()}
                        style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                        className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
                    >
                        <MdOutlineCancel />
                    </button>
                </div>

                <div className='flex-col border-t-1 border-color p-4 ml-4 space-y-6'>
                    <DropdownField
                        selected={selectedPermission}
                        setSelected={(e) => {
                            setSelectedPermission(e);
                            pathsModified.current = false; // Reset the pathsModified flag when changing permission
                        }}
                        title="Permission Group"
                        options={permissionGroups}
                        handleChangeText={(e) => setSelectedPermission(e)}
                        required={true}
                        error={selectedPermissionError}
                    />

                    <div>
                        <EditableTable
                            title="Advanced Page Control"
                            data={defaultPaths}
                            setData={setDefaultPaths}
                            rowActions={['Edit', 'Delete']}
                            addRow={true}
                            keyMapping={{
                                'Path': 'path'
                            }}
                        />
                    </div>

                    <div
                        onClick={() => {
                            if (!isLoading) {
                                setTimeout(submit, 100);
                            }
                        }}
                    >
                        <Button
                            bgColor={currentColor}
                            text="Submit"
                            size="md"
                            borderRadius={"12px"}
                            otherStyles="w-full items-center text-center font-semibold"
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditPageControl;

// Utility function to format date
function formatDateToMMDDYYYY(date) {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
}