import React, { useEffect, useState, useRef } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { useStateContext } from '../../../contexts/ContextProvider';
import EditableTable from '../../../components/EditableTable';
import { Button } from '../../../components';
import FormInput from '../../../components/FormField';
import { updateDocument, config, uploadData } from '../../../lib/appwrite';
import DateInput from '../../../components/DateField';
import DropdownField from '../../../components/DropdownField';
import MessageInput from '../../../components/MessageInput';

const EditCustomer = ({ view, setView, selectedRowData, setSelectedRowData, mode }) => {
    const { currentColor, account } = useStateContext();

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [phone, setPhone] = useState('')
    const [phoneError, setPhoneError] = useState(false)
    const [emergencyName, setEmergencyName] = useState('')
    const [emergencyNameError, setEmergencyNameError] = useState(false)
    const [emergencyPhone, setEmergencyPhone] = useState('')
    const [emergencyPhoneError, setEmergencyPhoneError] = useState(false)
    const [emergencyEmail, setEmergencyEmail] = useState('')
    const [emergencyEmailError, setEmergencyEmailError] = useState(false)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [streetAddress, setStreetAddress] = useState('')
    const [streetAddressError, setStreetAddressError] = useState(false)
    const [city, setCity] = useState('')
    const [cityError, setCityError] = useState(false)
    const [state, setState] = useState('')
    const [stateError, setStateError] = useState(false)
    const [zipcode, setZipcode] = useState('')
    const [zipCodeError, setZipcodeError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (view && selectedRowData) {
            setName(selectedRowData.name)
            setPhone(selectedRowData.phone)
            setEmergencyName(selectedRowData.emergencyName)
            setEmergencyPhone(selectedRowData.emergencyPhone)
            setEmergencyEmail(selectedRowData.emergencyEmail)
            setEmail(selectedRowData.email)
            setStreetAddress(selectedRowData.streetAddress)
            setCity(selectedRowData.city)
            setState(selectedRowData.state)
            setZipcode(selectedRowData.zipcode)
        }
    }, [view]);

    const close_menu = () => {
        setName('')
        setPhone('')
        setEmergencyName('')
        setEmergencyPhone('')
        setEmergencyEmail('')
        setEmail('')
        setStreetAddress('')
        setCity('')
        setState('')
        setZipcode('')
        setSelectedRowData(null);
        setView(false);
        setIsLoading(false);
    };

    const submit = async () => {
        if (name === '') { setNameError(true) } else { setNameError(false) }
        if (phone === '') { setPhoneError(true) } else { setPhoneError(false) }
        if (email === '') { setEmailError(true) } else { setEmailError(false) }
        if (streetAddress === '') { setStreetAddressError(true) } else { setStreetAddressError(false) }
        if (city === '') { setCityError(true) } else { setCityError(false) }
        if (state === '') { setStateError(true) } else { setStateError(false) }
        if (zipcode === '') { setZipcodeError(true) } else { setZipcodeError(false) }
        if (emergencyName === '') { setEmergencyNameError(true) } else { setEmergencyNameError(false) }
        if (emergencyPhone === '') { setEmergencyPhoneError(true) } else { setEmergencyPhoneError(false) }
        if (emergencyEmail === '') { setEmergencyEmailError(true) } else { setEmergencyEmailError(false) }

        if (
            name === '' ||
            phone === '' ||
            email === '' ||
            streetAddress === '' ||
            city === '' ||
            state === '' ||
            zipcode === '' ||
            emergencyName === '' ||
            emergencyPhone === '' ||
            emergencyEmail === ''
        ) { return }

        try {
            setIsLoading(true)

            const data = {
                name: name,
                phone: phone,
                email: email,
                streetAddress: streetAddress,
                city: city,
                state: state,
                zipcode: zipcode,
                emergencyName: emergencyName,
                emergencyPhone: emergencyPhone,
                emergencyEmail: emergencyEmail,
            }

            if (mode === 'edit') {
                const response = await updateDocument(selectedRowData.$id, data, config.customersCollectionId)
            } else {
                const response = await uploadData(data, config.customersCollectionId)
            }

            close_menu()
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='bg-half-transparent w-screen fixed nav-item top-0 right-0'>
            <div className='float-right h-screen overflow-scroll dark:text-gray-200 bg-white dark:bg-secondary-dark-bg w-[600px]'>
                <div className='flex justify-between items-center p-4 ml-4'>
                    {mode === 'edit' && (
                        <p className='font-semibold text-xl'>Edit Customer</p>
                    )}
                    {mode === 'create' && (
                        <p className='font-semibold text-xl'>Create Customer</p>
                    )}
                    {mode === 'view' && (
                        <p className='font-semibold text-xl'>Customer</p>
                    )}
                    <button
                        type='button'
                        onClick={() => close_menu()}
                        style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                        className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
                    >
                        <MdOutlineCancel />
                    </button>
                </div>

                <div className='flex-col border-t-1 border-color p-4 ml-4 space-y-6'>
                    <FormInput
                        title="Name"
                        placeholder="Name"
                        value={name}
                        handleChangeText={(e) => setName(e)}
                        error={nameError}
                        disabled={mode === 'view'}
                        required={mode !== 'view'}
                    />

                    <FormInput
                        title="Phone"
                        placeholder="Phone"
                        value={phone}
                        handleChangeText={(e) => setPhone(e)}
                        error={phoneError}
                        disabled={mode === 'view'}
                        required={mode !== 'view'}
                    />

                    <FormInput
                        title="Email"
                        placeholder="Email"
                        value={email}
                        handleChangeText={(e) => setEmail(e)}
                        error={emailError}
                        disabled={mode === 'view'}
                        required={mode !== 'view'}
                    />

                    <FormInput
                        title="Street Address"
                        placeholder="Street Address"
                        value={streetAddress}
                        handleChangeText={(e) => setStreetAddress(e)}
                        error={streetAddressError}
                        disabled={mode === 'view'}
                        required={mode !== 'view'}
                    />

                    <div className="grid-cols-2 grid">
                        <FormInput
                            otherStyles="pr-2"
                            title="City"
                            placeholder="City"
                            value={city}
                            handleChangeText={(e) => setCity(e)}
                            error={cityError}
                            disabled={mode === 'view'}
                            required={mode !== 'view'}
                        />
                        <FormInput
                            otherStyles="pl-2"
                            title="State"
                            placeholder="State"
                            value={state}
                            handleChangeText={(e) => setState(e)}
                            error={stateError}
                            disabled={mode === 'view'}
                            required={mode !== 'view'}
                        />
                        <FormInput
                            otherStyles={"pt-4"}
                            title="Zipcode"
                            placeholder="Zipcode"
                            value={zipcode}
                            handleChangeText={(e) => setZipcode(e)}
                            error={zipCodeError}
                            disabled={mode === 'view'}
                            required={mode !== 'view'}
                        />
                    </div>

                    <p className='font-semibold text-xl'>Emergency Contact</p>

                    <FormInput
                        title="Name"
                        placeholder="Name"
                        value={emergencyName}
                        handleChangeText={(e) => setEmergencyName(e)}
                        error={emergencyNameError}
                        disabled={mode === 'view'}
                        required={mode !== 'view'}
                    />

                    <FormInput
                        title="Phone"
                        placeholder="Phone"
                        value={emergencyPhone}
                        handleChangeText={(e) => setEmergencyPhone(e)}
                        error={emergencyPhoneError}
                        disabled={mode === 'view'}
                        required={mode !== 'view'}
                    />

                    <FormInput
                        title="Email"
                        placeholder="Email"
                        value={emergencyEmail}
                        handleChangeText={(e) => setEmergencyEmail(e)}
                        error={emergencyEmailError}
                        disabled={mode === 'view'}
                        required={mode !== 'view'}
                    />

                    <div
                        onClick={() => {
                            if (!isLoading) {
                                setTimeout(submit, 100)
                            }
                        }}
                    >
                        <Button
                            bgColor={currentColor}
                            text="Submit"
                            size="md"
                            borderRadius={"12px"}
                            otherStyles="w-full items-center text-center font-semibold text-white"
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default EditCustomer;