import React, { useEffect, useState, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page, Toolbar, Edit, Filter, Sort, create } from '@syncfusion/ej2-react-grids';
import { MdOutlineCancel } from 'react-icons/md'
import { config, updateDocument } from '../../../../lib/appwrite';
import { useStateContext } from '../../../../contexts/ContextProvider';
import FormInput from '../../../../components/FormField';
import DateInput from '../../../../components/DateField';

const EditUser = ({ editUserData, setEditUserData, selectedRowData }) => {
    const { currentColor } = useStateContext();

    const [username, setUsername] = useState('')
    const [usernameError, setUsernameError] = useState(false)

    useEffect(() => {
        if (editUserData && selectedRowData) {
            setUsername(selectedRowData.username)
        }
    }, [editUserData]);

    const close_menu = async () => {
        setUsername('')
        setEditUserData(false)
    }

    // Edit Function
    const actionBegin = (args) => {
        if (args.requestType === 'save') {
            const updatedData = args.data;

            // If it's an existing row, update the data in the array
            if (args.action === 'edit') {
                setTimeout(() => {
                    setJsonData(prevData => {
                        return prevData.map((item, index) => {
                            if (index === args.rowIndex) {
                                return updatedData;
                            }
                            return item;
                        });
                    });
                });
            }
            // If it's a new row, append the new data to the array
            else if (args.action === 'add' && args.row && args.row.cells[0].value) {
                setTimeout(() => {
                    setJsonData(prevData => {
                        return [...prevData, updatedData];
                    });
                });
            }
        }
    };



    // Submit Function
    const submit = async () => {
        if (username === '') { setUsernameError(true) } else { setUsernameError(false) }

        if (
            username === ''
        ) {
            return
        }

        const data = {
            username: username
        }
        const response = await updateDocument(selectedRowData.$id, data, config.userCollectionId)
        if (response) {
            console.error(response)
        } else {
            close_menu()
        }
    }

    return (
        <div className='bg-half-transparent w-screen fixed nav-item top-0 right-0'>
            <div className='float-right h-screen overflow-scroll dark:text-gray-200 bg-white dark:bg-secondary-dark-bg w-[600px]'>
                <div className='flex justify-between items-center p-4 ml-4'>
                    <p className='font-semibold text-xl'>Edit User</p>
                    <button
                        type='button'
                        onClick={() => setEditUserData()}
                        style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                        className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
                    >
                        <MdOutlineCancel />
                    </button>
                </div>

                <div className='flex-col border-t-1 border-color p-4 ml-4 space-y-6'>
                    <FormInput
                        title="Name"
                        placeholder="I-00000"
                        value={username}
                        handleChangeText={(e) => setUsername(e)}
                        error={usernameError}
                        required={true}
                    />

                    <button
                        className='w-full items-center text-md p-3 hover:drop-shadow-xl rounded-xl font-semibold'
                        onClick={() => submit()}
                        style={{ background: currentColor }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EditUser