import React from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css'; // Import the default stylesheet

const UserProfile = () => {
  const options = [
    'Cut',
    'Copy',
    'Paste'
  ];

  const defaultOption = options[0]; // Default selected option

  const handleChange = (selectedOption) => {
    // Handle option selection here
    console.log('Selected:', selectedOption);
  };

  return (
    <div>
      <Dropdown
        options={options}
        onChange={handleChange}
        value={defaultOption}
        placeholder="Select an option"
      />
    </div>
  );
};

export default UserProfile;
