import React, { useState } from 'react'
import { FaLock } from 'react-icons/fa'
import { IoPerson } from 'react-icons/io5'
import Users from './Users/Users'
import PageControl from './PageControl/AccessGroups'

const AccessControl = () => {
    const [currentTab, setCurrentTab] = useState('Users')
    const selectedTabStyle = 'border-indigo-500 text-indigo-600'
    const defaultTabStyle = 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'

    const tabs = [
        { name: 'Users', href: '#', icon: IoPerson },
        { name: 'Page Control', href: '#', icon: FaLock },
    ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    return (
        <div className='mx-2 px-2 md:px-10'>
            {/* Tab Manager */}
            <div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {tabs.map((tab) => (
                                <button
                                    key={tab.name}
                                    onClick={() => setCurrentTab(tab.name)}
                                    className={`${currentTab === tab.name ? selectedTabStyle : defaultTabStyle} group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium`}
                                    aria-current={tab.current ? 'page' : undefined}
                                >
                                    <tab.icon
                                        className={`${currentTab === tab.name ? "text-indigo-500" : 'group-hover:text-gray-500'} -ml-0.5 mr-2 h-5 w-5`}

                                        aria-hidden="true"
                                    />
                                    <span>{tab.name}</span>
                                </button>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
            {/* Tab Content */}
            <div className='mt-8 md:p-10 p-2 bg-white dark:bg-secondary-dark-bg rounded-3xl'>
                <div className={`${currentTab === 'Users' ? 'block' : 'hidden'}`}>
                    <Users />
                </div>
                <div className={`${currentTab === 'Page Control' ? 'block' : 'hidden'}`}>
                    <PageControl />
                </div>
            </div>

        </div>
    )
}

export default AccessControl