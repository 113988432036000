import React, { useEffect, useState, useRef } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { useStateContext } from '../../../contexts/ContextProvider';
import EditableTable from '../../../components/EditableTable';
import { Button } from '../../../components';
import FormInput from '../../../components/FormField';
import { updateDocument, config, uploadData } from '../../../lib/appwrite';
import DateInput from '../../../components/DateField';
import DropdownField from '../../../components/DropdownField';
import MessageInput from '../../../components/MessageInput';

const EditMessage = ({ view, setView, selectedRowData, setSelectedRowData, mode }) => {
    const { currentColor, account } = useStateContext();

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [subject, setSubject] = useState('')
    const [subjectError, setSubjectError] = useState(false)
    const [message, setMessage] = useState('')
    const [messageError, setMessageError] = useState(false)

    const [status, setStatus] = useState('')
    const [statusError, setStatusError] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (view && selectedRowData) {
            setName(selectedRowData.name)
            setEmail(selectedRowData.email)
            setSubject(selectedRowData.subject)
            setMessage(selectedRowData.message)
        }
    }, [view]);

    const close_menu = () => {
        setName('')
        setEmail('')
        setSubject('')
        setMessage('')
        setSelectedRowData(null);
        setView(false);
        setIsLoading(false);
    };

    const submit = async () => {
        if (name === '') { setNameError(true) } else { setNameError(false) }
        // if (lastInitial === '') { setLastInitialError(true) } else { setLastInitialError(false) }
        if (lastInitial.length !== 1) { setLastInitialError(true) } else { setLastInitialError(false) }
        if (review === '') { setReviewError(true) } else { setReviewError(false) }

        if (
            name === '' ||
            lastInitial.length !== 1 ||
            review === ''
        ) { return }

        try {
            setIsLoading(true)

            const data = {
                name: name,
                lastInitial: lastInitial,
                review: review,
                status: status
            }

            const response = await updateDocument(selectedRowData.$id, data, config.testimonialCollectionId)

            close_menu()
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='bg-half-transparent w-screen fixed nav-item top-0 right-0'>
            <div className='float-right h-screen overflow-scroll dark:text-gray-200 bg-white dark:bg-secondary-dark-bg w-[600px]'>
                <div className='flex justify-between items-center p-4 ml-4'>
                    {mode === 'edit' && (
                        <p className='font-semibold text-xl'>Edit Message</p>
                    )}
                    {mode === 'create' && (
                        <p className='font-semibold text-xl'>Create Message</p>
                    )}
                    {mode === 'view' && (
                        <p className='font-semibold text-xl'>Message</p>
                    )}
                    <button
                        type='button'
                        onClick={() => close_menu()}
                        style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                        className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
                    >
                        <MdOutlineCancel />
                    </button>
                </div>

                <div className='flex-col border-t-1 border-color p-4 ml-4 space-y-6'>
                    <FormInput
                        title="Name"
                        placeholder="Name"
                        value={name}
                        handleChangeText={(e) => setName(e)}
                        error={nameError}
                        disabled={mode === 'view'}
                        required={mode !== 'view'}
                    />

                    <FormInput
                        title="Email"
                        placeholder="Email"
                        value={email}
                        handleChangeText={(e) => setEmail(e)}
                        error={emailError}
                        disabled={mode === 'view'}
                        required={mode !== 'view'}
                    />

                    <MessageInput
                        title={"Message"}
                        value={message}
                        placeholder={"Message"}
                        handleChangeText={(e) => setMessage(e)}
                        required={mode !== 'view'}
                        disabled={mode === 'view'}
                        error={messageError}
                    />

                    <div
                        className={`${mode === 'view' ? 'hidden': 'block'}`}
                        onClick={() => {
                            if (!isLoading) {
                                setTimeout(submit, 100)
                            }
                        }}
                    >
                        <Button
                            bgColor={currentColor}
                            text="Submit"
                            size="md"
                            borderRadius={"12px"}
                            otherStyles="w-full items-center text-center font-semibold text-white"
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default EditMessage;