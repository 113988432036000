import React, { useEffect, useState } from 'react';
import { FiMenu } from 'react-icons/fi'; // Import hamburger menu icon from react-icons

const WebNavbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className={`bg-[#fff] transition-all duration-500 z-[997] ${scrolled ? "py-[12px] shadow-md" : "py-[20px]"} fixed top-0 left-0 right-0 items-center`}>
        <div className='mx-[15%] flex'>
          <h1 className='text-[30px] m-0 p-0 font-[600] mr-auto'><a className='text-[#111111]' href="/">Wildlife Comforts<span className='text-[#4caf50]'>.</span></a></h1>
          {/* Hamburger menu icon for mobile */}
          <div className='md:hidden flex items-center'>
            <FiMenu className='text-[#111111] cursor-pointer text-3xl' onClick={toggleMobileMenu} />
          </div>
          {/* Dropdown menu */}
          <div className={`md:hidden absolute top-[80px] left-0 right-0 bg-white shadow-md py-2 transition-all duration-300 ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
            <ul className='m-0 p-0 flex flex-col items-center'>
              <li className='relative'><a className='flex items-center justify-center py-2.5 pl-8 text-[15px] font-[600] text-[#111111] text-nowrap transition-all duration-300 hover:text-[#4caf50]' href="/#home">Home</a></li>
              <li className='relative'><a className='flex items-center justify-center py-2.5 pl-8 text-[15px] font-[600] text-[#111111] text-nowrap transition-all duration-300 hover:text-[#4caf50]' href="/pricing">Pricing</a></li>
              <li className='relative'><a className='flex items-center justify-center py-2.5 pl-8 text-[15px] font-[600] text-[#111111] text-nowrap transition-all duration-300 hover:text-[#4caf50]' href="/#about">About</a></li>
              <li className='relative'><a className='flex items-center justify-center py-2.5 pl-8 text-[15px] font-[600] text-[#111111] text-nowrap transition-all duration-300 hover:text-[#4caf50]' href="/#services">Services</a></li>
              <li className='relative'><a className='flex items-center justify-center py-2.5 pl-8 text-[15px] font-[600] text-[#111111] text-nowrap transition-all duration-300 hover:text-[#4caf50]' href="/#portfolio">Portfolio</a></li>
              <li className='relative'><a className='flex items-center justify-center py-2.5 pl-8 text-[15px] font-[600] text-[#111111] text-nowrap transition-all duration-300 hover:text-[#4caf50]' href="/#contact">Contact</a></li>
            </ul>
          </div>

          {/* Regular menu for larger screens */}
          <div className={`md:flex justify-end items-center hidden ${isMobileMenuOpen ? 'hidden' : 'block'}`}>
            <ul className='m-0 p-0 flex list-none items-center'>
              <li className='relative'><a className='flex items-center justify-between py-2.5 pl-8 text-[15px] font-[600] text-[#111111] text-nowrap transition-all duration-300 hover:text-[#4caf50]' href="/#home">Home</a></li>
              <li className='relative'><a className='flex items-center justify-between py-2.5 pl-8 text-[15px] font-[600] text-[#111111] text-nowrap transition-all duration-300 hover:text-[#4caf50]' href="/pricing">Pricing</a></li>
              <li className='relative'><a className='flex items-center justify-between py-2.5 pl-8 text-[15px] font-[600] text-[#111111] text-nowrap transition-all duration-300 hover:text-[#4caf50]' href="/#about">About</a></li>
              <li className='relative'><a className='flex items-center justify-between py-2.5 pl-8 text-[15px] font-[600] text-[#111111] text-nowrap transition-all duration-300 hover:text-[#4caf50]' href="/#services">Services</a></li>
              <li className='relative'><a className='flex items-center justify-between py-2.5 pl-8 text-[15px] font-[600] text-[#111111] text-nowrap transition-all duration-300 hover:text-[#4caf50]' href="/#portfolio">Portfolio</a></li>
              <li className='relative'><a className='flex items-center justify-between py-2.5 pl-8 text-[15px] font-[600] text-[#111111] text-nowrap transition-all duration-300 hover:text-[#4caf50]' href="/#contact">Contact</a></li>
            </ul>

            <a href="/#about" className='ml-[30px] bg-[#4caf50] text-[#fff] rounded-md px-[25px] py-[8px] whitespace-nowrap transition-all duration-300 text-[14px] font-[600] inline-block hover:bg-[#111111] hover:text-[#fff]'>Learn More</a>
          </div>

        </div>
      </div>
    </div>
  );
};

export default WebNavbar;
