import React, { useEffect, useState } from 'react';
// import '../../css/Home.css';
import hero_bg from '../../assets/hero-bg.png';

import { LuHeartHandshake } from "react-icons/lu";
import { MdOutlineFamilyRestroom } from "react-icons/md";
import { GiBrain } from "react-icons/gi";
import { FaRegHandshake } from "react-icons/fa6";
import { IoPawOutline } from "react-icons/io5";
import { FaRegSmile } from "react-icons/fa";
import { BsHouseDoor } from "react-icons/bs";
import { BiSolidBinoculars } from "react-icons/bi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaDog } from "react-icons/fa6";
import { FaTaxi } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import PortfolioCard from '../../components/PortfolioCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { BsEnvelope } from "react-icons/bs";
import { CiMap } from "react-icons/ci";
import { MdOutlinePhone } from "react-icons/md";
import MessageInput from '../../components/MessageInput'
import FormField from '../../components/FormField'
import Button from '../../components/Button'


import 'swiper/css';
import 'swiper/css/pagination';
import Testimonial from '../../components/Testimonial';
import useAppwrite from '../../lib/useAppwrite';
import { config, getAllDocuments, uploadData } from '../../lib/appwrite';
import TestimonialSwiper from '../../components/TestimonialSwiper';


const Home = () => {
    const [activePortfolioFilter, setActivePortfolioFilter] = useState('All')
    const [errorMessage, setErrorMessage] = useState('')
    const [sentMessage, setSentMessage] = useState(false)

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [subject, setSubject] = useState('')
    const [subjectError, setSubjectError] = useState(false)
    const [message, setMessage] = useState('')
    const [messageError, setMessageError] = useState(false)

    const [isSubmitting, setIsSubmitting] = useState(false)

    const { data: testimonialCards, isLoading, refetch } = useAppwrite(
        () => getAllDocuments(config.testimonialCollectionId, null, [{ status: 'Approved' }])
    );

    const { data: porfolioCards } = useAppwrite(
        () => getAllDocuments(config.photosCollectionId)
    );

    useEffect(() => {
        if (sentMessage) {
            const timer = setTimeout(() => {
                setSentMessage(false);
                setName('')
                setEmail('')
                setSubject('')
                setMessage('')
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [sentMessage]);

    const submitMessage = async () => {
        if (name === '') { setNameError(true) } else { setNameError(false) }
        if (email === '') { setEmailError(true) } else { setEmailError(false) }
        if (subject === '') { setSubjectError(true) } else { setSubjectError(false) }
        if (message === '') { setMessageError(true) } else { setMessageError(false) }

        if (
            name === '' ||
            email === '' ||
            subject === '' ||
            message === ''
        ) { return }

        try {
            setIsSubmitting(true)

            const data = {
                name: name,
                email: email,
                subject: subject,
                message: message
            }

            const response = await uploadData(data, config.messagesCollectionId)
            setSentMessage(true)
        } catch (error) {
            console.error(error)
            setNameError(false)
            setEmailError(false)
            setSubjectError(false)
            setMessageError(false)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <div>
            {/* Hero Section */}
            <section
                id="home"
                className={`relative flex items-center h-screen bg-cover bg-center pt-20`}
                style={{
                    backgroundImage: `url(${hero_bg})`,
                    backgroundPosition: 'center', // Default position for large screens
                }}
            >
                <div className="container px-6 md:px-0 mx-[15%]" data-aos="zoom-out" data-aos-delay="100">
                    <div className="flex flex-col lg:flex-row">
                        <div className="w-full lg:w-1/2">
                            <h1 className="m-0 text-[48px] font-bold text-[#111]">Where Every Creature Finds Peace</h1>
                            {/* <h2>second tagline</h2> */}
                            <a
                                href="#about"
                                className="font-medium text-[16px] inline-block py-[10px] px-[30px] rounded-md transition-all duration-500 mt-[30px] text-white bg-[#4caf50] border-2 border-[#4caf50] hover:bg-transparent hover:border-white hover:text-black"
                            >
                                Learn More
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/* About Section */}
            <div className="relative">
                <div className="absolute inset-0 bg-[#1b1b1b] transform -skew-y-3"></div>
                <section id="about" className="py-32 text-white mx-[15%]">
                    <div className="relative z-10 mx-auto">
                        <div className="grid grid-cols-3 md:grid-cols-2">
                            <div className="col-span-3 md:col-span-1">
                                <div className="mt-6 md:mt-0 mr-6 md:mr-0 mb-6 md:mb-0 ml-0">
                                    <h3 className='font-bold text-2xl md:text-3xl mb-6 md:mb-8'>Our Mission</h3>
                                    <p className='mb-6 md:mb-8'>
                                        Wildlife Comforts offers compassionate and reliable animal sitting services, ensuring the happiness and well-being of your beloved pets while you're away.
                                    </p>
                                </div>
                            </div>
                            <div className="col-span-3 md:col-span-1">
                                <div className="icon-boxes grid md:grid-cols-2 sm:grid-cols-1 gap-4">
                                    <div className="mt-6 md:mt-0" data-aos="fade-up" data-aos-delay="100">
                                        <i className='text-4xl text-[#4caf50] mb-6'><MdOutlineFamilyRestroom /></i>
                                        <h4 className='text-lg font-bold m-0 mb-4'>Part of the Family</h4>
                                        <p className='text-sm text-[#848484]'>Animals at Wildlife Comforts are family, receiving love and care as our own.</p>
                                    </div>
                                    <div className="mt-6 md:mt-0" data-aos="fade-up" data-aos-delay="200">
                                        <i className='text-4xl text-[#4caf50] mb-6'><LuHeartHandshake /></i>
                                        <h4 className='text-lg font-bold m-0 mb-4'>Compassion</h4>
                                        <p className='text-sm text-[#848484]'>With compassion, we ensure each animal feels understood and nurtured.</p>
                                    </div>
                                    <div className="mt-6 md:mt-0" data-aos="fade-up" data-aos-delay="300">
                                        <i className='text-4xl text-[#4caf50] mb-6'><GiBrain /></i>
                                        <h4 className='text-lg font-bold m-0 mb-4'>Understanding</h4>
                                        <p className='text-sm text-[#848484]'>Tailoring care to unique needs, we create a comforting environment.</p>
                                    </div>
                                    <div className="mt-6 md:mt-0" data-aos="fade-up" data-aos-delay="400">
                                        <i className='text-4xl text-[#4caf50] mb-6'><FaRegHandshake /></i>
                                        <h4 className='text-lg font-bold m-0 mb-4'>Respect</h4>
                                        <p className='text-sm text-[#848484]'>We honor each animal's dignity and autonomy with reverence.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>

            {/* Counts Section */}
            <section id="counts" className="py-16 pt-[80px]">
                <div className="mx-[15%]" data-aos="fade-up">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        <div className="col-span-1"></div>
                        <div className="col-span-1 lg:col-span-3">
                            <div className="flex flex-col md:flex-row md:justify-between">
                                <div className="p-[30px] pb-[25px] w-full relative text-center shadow-md rounded-md">
                                    <i className='absolute w-14 h-14 top-[-27px] left-1/2 transform -translate-x-1/2 flex items-center justify-center bg-white text-theme1 rounded-full border-2 border-white shadow-md'><FaRegSmile className='text-2xl text-[#4caf50]' /></i>
                                    <span className="text-[36px] block font-[700] text-[#111111]">20</span>
                                    <p className="text-center p-0 m-0 text-[14px]">Happy Clients</p>
                                </div>
                                <div className="p-[30px] pb-[25px] w-full relative text-center shadow-md rounded-md mt-5 md:mt-0">
                                    <i className='absolute w-14 h-14 top-[-27px] left-1/2 transform -translate-x-1/2 flex items-center justify-center bg-white text-theme1 rounded-full border-2 border-white shadow-md'><IoPawOutline className='text-2xl text-[#4caf50]' /></i>
                                    <span className="text-[36px] block font-[700] text-[#111111]">50</span>
                                    <p className="text-center p-0 m-0 text-[14px]">Animals Helped</p>
                                </div>
                                <div className="p-[30px] pb-[25px] w-full relative text-center shadow-md rounded-md mt-5 md:mt-0">
                                    <i className='absolute w-14 h-14 top-[-27px] left-1/2 transform -translate-x-1/2 flex items-center justify-center bg-white text-theme1 rounded-full border-2 border-white shadow-md'><BsHouseDoor className='text-2xl text-[#4caf50]' /></i>
                                    <span className="text-[36px] block font-[700] text-[#111111]">15</span>
                                    <p className="text-center p-0 m-0 text-[14px]">Houses Cared For</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1"></div>
                    </div>
                </div>
            </section>

            {/* Services Section */}
            <div className="relative" id="services">
                <div className="absolute inset-0 bg-[#1b1b1b] transform -skew-y-3"></div>
                <section className="py-32 text-white mx-[15%]">
                    <div className="mx-auto" data-aos="fade-up">
                        <div className="text-center pb-[30px] relative">
                            <div className="text-[32px] font-bold uppercase mb-5 pb-5 relative">
                                <h2>Services</h2>
                                <span className="block w-14 h-1 bg-[#4caf50] absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
                            </div>
                            <p className='mb-0'>At Wildlife Comforts, we offer a range of personalized services tailored to meet the unique needs of each animal:</p>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            <div className="mt-8">
                                <div className="mb-[20px] p-[30px] rounded-md bg-[#252525] transition-all duration-300 relative hover:bg-[#2b2b2b]" data-aos="fade-up" data-aos-delay="100">
                                    <i className='float-left text-[#4caf50] text-[40px]'><BiSolidBinoculars /></i>
                                    <h4 className="ml-[70px] font-[700] mb-[15px] text-[18px]">Animal Sitting</h4>
                                    <p className="ml-[70px] text-[14px] mb-[10px]">Whether you're going away for a weekend or an extended vacation, our experienced team provides reliable and compassionate animal sitting services. We ensure your furry, feathered, or scaly friends receive the attention and care they deserve in the comfort of their own environment.</p>
                                </div>
                            </div>
                            <div className="mt-8">
                                <div className="mb-[20px] p-[30px] rounded-md bg-[#252525] transition-all duration-300 relative hover:bg-[#2b2b2b]" data-aos="fade-up" data-aos-delay="200">
                                    <i className='float-left text-[#4caf50] text-[40px]'><FaMapMarkerAlt /></i>
                                    <h4 className="ml-[70px] font-[700] mb-[15px] text-[18px]">Daily Visits</h4>
                                    <p className="ml-[70px] text-[14px] mb-[10px]">Our daily visit services are perfect for pet owners who prefer their animals to remain in the familiar surroundings of home while they're away. We'll stop by at scheduled times to feed, play, and provide companionship to your pets, ensuring they feel loved and cared for in your absence.</p>
                                </div>
                            </div>
                            <div className="">
                                <div className="mb-[20px] p-[30px] rounded-md bg-[#252525] transition-all duration-300 relative hover:bg-[#2b2b2b]" data-aos="fade-up" data-aos-delay="300">
                                    <i className='float-left text-[#4caf50] text-[40px]'><FaDog /></i>
                                    <h4 className="ml-[70px] font-[700] mb-[15px] text-[18px]">Dog Walking</h4>
                                    <p className="ml-[70px] text-[14px] mb-[10px]">Keep your canine companions happy and active with our professional dog walking services. Whether it's a leisurely stroll around the neighborhood or an energetic romp in the park, our dog walkers are dedicated to providing exercise, mental stimulation, and lots of tail-wagging fun for your furry friends.</p>
                                </div>
                            </div>
                            <div className="">
                                <div className="mb-[20px] p-[30px] rounded-md bg-[#252525] transition-all duration-300 relative hover:bg-[#2b2b2b]" data-aos="fade-up" data-aos-delay="400">
                                    <i className='float-left text-[#4caf50] text-[40px]'><FaTaxi /></i>
                                    <h4 className="ml-[70px] font-[700] mb-[15px] text-[18px]">Pet Taxi</h4>
                                    <p className="ml-[70px] text-[14px] mb-[10px]">Need help transporting your pet to the vet, groomer, or daycare? Our pet taxi service ensures safe and stress-free transportation for your animals. We'll handle all the logistics, so you can rest assured knowing your pet will arrive at their destination comfortably and on time.</p>
                                </div>
                            </div>
                            <div className="">
                                <div className="mb-[20px] p-[30px] rounded-md bg-[#252525] transition-all duration-300 relative hover:bg-[#2b2b2b]" data-aos="fade-up" data-aos-delay="400">
                                    <i className='float-left text-[#4caf50] text-[40px]'><FaRegHeart /></i>
                                    <h4 className="ml-[70px] font-[700] mb-[15px] text-[18px]">Specialized Care</h4>
                                    <p className="ml-[70px] text-[14px] mb-[10px]">From administering medication to providing specialized care for senior pets or animals with special needs, our team is trained to handle a variety of situations with skill and compassion. We work closely with pet owners to develop personalized care plans that address the unique requirements of each animal.</p>
                                </div>
                            </div>
                            <div className="">
                                <div className="mb-[20px] p-[30px] rounded-md bg-[#252525] transition-all duration-300 relative hover:bg-[#2b2b2b]" data-aos="fade-up" data-aos-delay="400">
                                    <i className='float-left text-[#4caf50] text-[40px]'><BsHouseDoor /></i>
                                    <h4 className="ml-[70px] font-[700] mb-[15px] text-[18px]">House Sitting</h4>
                                    <p className="ml-[70px] text-[14px] mb-[10px]">In addition to caring for your animals, we also offer house sitting services to give you peace of mind while you're away. Our trustworthy sitters will keep a watchful eye on your home, collect mail, water plants, and ensure everything runs smoothly in your absence.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* Portfolio Section */}
            <section id="portfolio">
                <div className="mx-[15%]" data-aos="fade-up">
                    <div className="text-center pb-[30px] relative">
                        <div className="text-[32px] font-bold uppercase mb-5 pb-5 relative">
                            <h2>Portfolio</h2>
                            <span className="block w-14 h-1 bg-[#4caf50] absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
                        </div>
                        <p className='mb-0'>Take a peek into some of the heartwarming moments we've shared with the animals in our care at Wildlife Comforts:</p>
                    </div>
                    {/* <div className="flex justify-center">
                        <ul id="portfolio-flters" className="flex space-x-4 p-0 mt-0 mr-auto mb-[20px] ml-auto text-center">
                            <li className={`${activePortfolioFilter === 'All' ? 'text-[#fff] bg-[#4caf50]' : 'text-[#444444]'} hover:text-[#fff] hover:bg-[#4caf50] cursor-pointer inline-block pt-[8px] pr-[15px] pb-[10px] pl-[15px] text-[14px] font-[600] uppercase mb-[5px] transition-all ease-in-out duration-300 rounded-md`}>All</li>
                            <li className={`${activePortfolioFilter === 'Animal Sitting' ? 'text-[#fff] bg-[#4caf50]' : 'text-[#444444]'} hover:text-[#fff] hover:bg-[#4caf50] cursor-pointer inline-block pt-[8px] pr-[15px] pb-[10px] pl-[15px] text-[14px] font-[600] uppercase mb-[5px] transition-all ease-in-out duration-300 rounded-md`}>Animal Sitting</li>
                            <li className={`${activePortfolioFilter === 'Daily Visits' ? 'text-[#fff] bg-[#4caf50]' : 'text-[#444444]'} hover:text-[#fff] hover:bg-[#4caf50] cursor-pointer inline-block pt-[8px] pr-[15px] pb-[10px] pl-[15px] text-[14px] font-[600] uppercase mb-[5px] transition-all ease-in-out duration-300 rounded-md`}>Daily Visits</li>
                            <li className={`${activePortfolioFilter === 'Extended Care' ? 'text-[#fff] bg-[#4caf50]' : 'text-[#444444]'} hover:text-[#fff] hover:bg-[#4caf50] cursor-pointer inline-block pt-[8px] pr-[15px] pb-[10px] pl-[15px] text-[14px] font-[600] uppercase mb-[5px] transition-all ease-in-out duration-300 rounded-md`}>Extended Care</li>
                            <li className={`${activePortfolioFilter === 'Specialized Services' ? 'text-[#fff] bg-[#4caf50]' : 'text-[#444444]'} hover:text-[#fff] hover:bg-[#4caf50] cursor-pointer inline-block pt-[8px] pr-[15px] pb-[10px] pl-[15px] text-[14px] font-[600] uppercase mb-[5px] transition-all ease-in-out duration-300 rounded-md mr-0`}>Specialized Services</li>
                        </ul>
                    </div> */}
                    <div className="flex flex-wrap">
                        {porfolioCards.map((card, index) => (
                            <PortfolioCard
                                key={index} // Always add a key when rendering lists
                                name={card.name}
                                caption={card.caption}
                                url={card.photoUrl}
                            />
                        ))}
                    </div>
                </div>
            </section>

            {/* <!-- ======= Testimonials Section ======= --> */}
            <div className="relative py-10">
                <div className="absolute inset-0 bg-[#1b1b1b] transform -skew-y-3"></div>
                <div className='relative z-10'>
                    <div className='px-[15%] py-[20px]'>
                        <TestimonialSwiper
                            testimonials={testimonialCards}
                        />
                    </div>
                </div>
            </div>

            {/* Contact Section */}
            <section id="contact" className="mx-[15%]">
                <div className="mx-auto" data-aos="fade-up">
                    <div className="text-center pb-[30px] relative">
                        <div className="text-[32px] font-bold uppercase mb-5 pb-5 relative">
                            <h2>Contact</h2>
                            <span className="block w-14 h-1 bg-[#4caf50] absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
                        </div>
                        <p className='mb-0'>If you have any questions or would like to learn more about our services, please feel free to contact us:</p>
                    </div>
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-1/2 p-4">
                            <div className="mb-4 text-[#444444] text-center shadow-xl p-0 pt-[30px] pb-[32px] rounded-md">
                                <CiMap
                                    className="rounded-full text-[32px] text-[#4caf50] border-dotted border-2 border-[#A9D68E] mx-auto"
                                />
                                <h3 className="text-[20px] text-[#777777] font-[700] my-[10px] mx-0">Our Address</h3>
                                <p className="p-0 text-[14px] mb-0">Columbia, MD</p>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2 p-4">
                            <div className="text-[14px] shadow-xl p-[30px] pb-[32px] rounded-md">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <FormField
                                        value={name}
                                        placeholder={"Your Name"}
                                        handleChangeText={(e) => setName(e)}
                                        error={nameError}
                                    />
                                    <FormField
                                        value={email}
                                        placeholder={"Your Email"}
                                        handleChangeText={(e) => setEmail(e)}
                                        error={emailError}
                                    />
                                </div>
                                <FormField
                                    value={subject}
                                    placeholder={"Subject"}
                                    handleChangeText={(e) => setSubject(e)}
                                    error={subjectError}
                                />
                                <MessageInput
                                    value={message}
                                    placeholder={"Message"}
                                    handleChangeText={(e) => (setMessage(e))}
                                    error={messageError}
                                />
                                <div className="my-3">
                                    <div className={`${errorMessage === '' ? 'hidden' : 'block'}`}>{errorMessage}</div>
                                    <div className={`${sentMessage === false ? 'hidden' : 'block'} text-center text-green-600`}>Your message has been sent. Thank you!</div>
                                </div>
                                <div className="text-center" onClick={() => submitMessage()}>
                                    <Button
                                        bgColor={"#4caf50"}
                                        color={"white"}
                                        text={"Send Message"}
                                        borderRadius={"10px"}
                                        isLoading={isSubmitting}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Home;
