
// Auth
export { default as SignIn } from './auth/SignIn';
export { default as SignUp } from './auth/SignUp'; 

// Backend
export { default as Dashboard } from './backend/Dashboard';
export { default as AccessControl } from './backend/AccessControl/AccessControl'
export { default as Testimonials } from './backend/testimonials/Testimonials'
export { default as Messages } from './backend/messages/Messages'
export { default as Photos } from './backend/photos/Photos'
export { default as Customers } from './backend/customers/Customers'
export { default as Calendar } from './backend/calendar/Calendar'

// Front End
export { default as Home } from './home/Home'
export { default as Review } from './home/Review'
export { default as Booking } from './home/Booking'
export { default as Pricing } from './home/Pricing'
