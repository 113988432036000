import React from 'react';
import noPhoto from '../assets/portfolio/placeholder.jpeg';

const PortfolioCard = ({ key, name, caption, url }) => {
  return (
    <div id={key} className="w-full md:w-1/2 lg:w-1/3 p-4 mb-[30px]">
      <div className="relative group overflow-hidden transition-all duration-300">
        {/* This div acts as the ::before pseudo-element */}
        <div className="absolute inset-0 bg-[rgba(17,17,17,0.6)] transition-opacity duration-300 opacity-0 group-hover:opacity-100 z-10"></div>
        <img src={url && url !== '' ? url : noPhoto} className="w-full h-auto transition-transform duration-300 transform group-hover:scale-105" alt={name} />
        <div className="absolute bottom-0 left-0 w-full p-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-20">
          <h4 className="text-white">{name}</h4>
          <p className="text-white">{caption}</p>
          <div className="flex space-x-2">
            <a href={url && url !== '' ? url : noPhoto} data-gallery="portfolioGallery" className="" title={name}>
              <i className="bx bx-plus text-white"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioCard;
